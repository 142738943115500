/**
 *    SPDX-License-Identifier: Apache-2.0
 */

const namespaces = 'theme';

const CHANGE_THEME = `${namespaces}/CHANGE_THEME`;
const CHANGE_MODE_TYPE = `${namespaces}/CHANGE_MODE_TYPE`;

export default {
  CHANGE_THEME,
  CHANGE_MODE_TYPE
};
