/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from "react";
import View from "../Styled/View";
import Transactions from "../Lists/Transactions";
import IntegraTransaction from "../Lists/IntegraTransaction";
import { useLocation } from "react-router-dom";
import {
  currentChannelType,
  getTransactionType,
  getTransactionInfoType,
  getTransactionListType,
  transactionType,
  transactionListType,
} from "../types";

export const TransactionsView = ({
  currentChannel,
  getTransaction,
  getTransactionInfo,
  getTransactionList,
  transaction,
  transactionList,
  getTransactionListSearch,
  transactionByOrg,
  transactionListSearch,
  match,
  ...other
}) => {
  const query = new URLSearchParams(useLocation().search);

  return (
    <View>
		{query.get("type") === 'integra_id' ? 
		<IntegraTransaction
        currentChannel={currentChannel}
        transactionList={transactionList}
        getTransactionList={getTransactionList}
        transaction={transaction}
        transactionByOrg={transactionByOrg}
        getTransactionInfo={getTransactionInfo}
        getTransaction={getTransaction}
        getTransactionListSearch={getTransactionListSearch}
        transactionListSearch={transactionListSearch}
        type={query.get("type")}
        q={query.get("q")}
        orgType= {query.get("orgType")}
      />
		:
      <Transactions
        currentChannel={currentChannel}
        transactionList={transactionList}
        getTransactionList={getTransactionList}
        transaction={transaction}
        transactionByOrg={transactionByOrg}
        getTransactionInfo={getTransactionInfo}
        getTransaction={getTransaction}
        getTransactionListSearch={getTransactionListSearch}
        transactionListSearch={transactionListSearch}
        type={query.get("type")}
        q={query.get("q")}
        orgType= {query.get("orgType")}
      />
      
  }
    </View>
  );
};

TransactionsView.propTypes = {
  currentChannel: currentChannelType.isRequired,
  getTransaction: getTransactionType.isRequired,
  getTransactionInfo: getTransactionInfoType.isRequired,
  getTransactionList: getTransactionListType.isRequired,
  transaction: transactionType.isRequired,
  transactionList: transactionListType.isRequired,
};

export default TransactionsView;
