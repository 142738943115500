export const responseToIncrementalData = (res) => {
  res.rows = res.rows.map((record, index) => {
    const preValue = index === 0 ? 0 : res.rows[index - 1].count;
    record.count =
      Number(isNaN(preValue) ? 0 : preValue) + Number(record.count);
    return record;
  });
};

export const distructRows = (rows) => {
  let exactRows = [];
  for (let i = rows.length - 1; i >= 0; i--) {
    exactRows.push({
      count: i === 0 ? 0 : rows[i].count - rows[i - 1].count,
      datetime: rows[i].datetime,
    });
  }
  exactRows.reverse();
  return exactRows;
};
