/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import HeaderPublic from '../HeaderPublic';
import Footer from '../Footer';
import JSONTree from 'react-json-tree';
import { authSelectors, authOperations } from '../../state/redux/auth';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import iCopy from './../../static/icon/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { checkEmbedRoute } from "./../../helpers/checkEmbedRoute";

moment.locale('de');
const jsonTheme = {
	base00: '#ffffff00',
	base01: '#2e2f30',
	base02: '#515253',
	base03: '#737475',
	base04: '#959697',
	base05: '#b7b8b9',
	base06: '#dadbdc',
	base07: '#fcfdfe',
	base08: '#e31a1c',
	base09: '#e6550d',
	base0A: '#dca060',
	base0B: '#31a354',
	base0C: '#80b1d3',
	base0D: '#3182bd',
	base0E: '#756bb1',
	base0F: '#b15928'
};

const { authSelector } = authSelectors;
export class TransactionDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isHeaderVisible: true,
		  };
	}

	componentDidMount() {
		const txHash = this.props.match.params.id;
		this.props.getDetailTransaction(txHash).then(res => {
			if (res?.row) {
				this.setState(res.row);
			}
		});

		const isEmbeded = checkEmbedRoute();
		if (isEmbeded === 'true') { 
		  this.setState({ isHeaderVisible: false });
		}

	}

	renderListItem = row => {
		const elements = [];
		const mapping = {
			txhash: {
				name: 'Transaction ID',
				render: v => {
					return (
						<>
							<div className="text">{v}</div>
							<div className="btn-copy">
								<CopyToClipboard text={v}>
									<img src={iCopy} alt="copy" />
								</CopyToClipboard>
							</div>
						</>
					);
				}
			},
			blockid: 'Block',
			// id: 'IntegraID',
			channelname: 'Channel Name',
			validation_code: 'Validation Code',
			payload_proposal_hash: 'Payload Proposal Hash',
			creator_msp_id: 'Creator MSP',
			endorser_msp_id: 'Endorser',
			chaincodename: 'Chaincode Name',
			type: 'Type',
			createdt: {
				name: 'Time',
				render: v => {
					return moment(v)
						.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
						.format('DD/MM/YYYY - hh:mm:ss');
				}
			},
			read_set: {
				name: 'Reads',
				render: v => {
					return (
						<div className="font-weight-normal">
							<JSONTree data={v} theme={jsonTheme} invertTheme={false} />
						</div>
					);
				}
			},
			write_set: {
				name: 'Writes',
				render: v => {
					return (
						<div className="font-weight-normal">
							<JSONTree data={v} theme={jsonTheme} invertTheme={false} />
						</div>
					);
				}
			}
		};

		// Render item
		for (const key in mapping) {
			const value = row[key];
			const isObject = typeof mapping[key] === 'object';
			// Push
			elements.push(
				<Row>
					<Col className="cell" md={3} xs={5}>
						{isObject ? mapping[key].name : mapping[key]}:
					</Col>
					<Col className="cell">
						{isObject ? mapping[key].render(value) : value}
					</Col>
				</Row>
			);
		}
		return elements;
	};

	render() {
		const row = this.state;
		const { auth } = this.props;

		if (!row) return <></>;

		return (
			<>
				{!auth && this.state.isHeaderVisible && <HeaderPublic />}
				<div className={this.state.isHeaderVisible ? 'isHeaderVisible' : 'isHeaderhide'}>
					<div className="detail-box">
						<div className="box-title">Transaction Details</div>
						<div className="box-title-sub">Overview</div>
						<div className="box-table">{this.renderListItem(row)}</div>
					</div>
				</div>
				{!auth && this.state.isHeaderVisible && <Footer />}
			</>
		);
	}
}

export default compose(
	connect(
		state => ({
			auth: authSelector(state)
		}),
		{
			getDetailTransaction: authOperations.transactionDetail
		}
	)
)(TransactionDetail);
