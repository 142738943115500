export const checkEmbedRoute = () => {
  const currentURL = window.location.href;
  let isEmbeded = false;
  const urlString = currentURL;
  let newURL = urlString.replace("#/", "");
  const url = new URL(newURL);
  if (url.searchParams.has("embed")) {
    isEmbeded = url.searchParams.get("embed");
    return isEmbeded;
  }
};
