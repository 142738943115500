/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { TabContent, TabPane } from 'reactstrap';
import { chartSelectors, chartOperations } from '../../state/redux/charts';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
	blockPerHourType,
	blockPerMinType,
	blockPerDayType,
	blockAllType,
	currentChannelType,
	getBlocksPerHourType,
	getBlocksPerMinType,
	getTransactionPerHourType,
	getTransactionPerMinType,
	transactionPerHourType,
	transactionPerMinType,
	transactionPerDayType,
	transactionAllType
} from '../types';
import {distructRows} from "../../utils"
import AreaChartIcon from '../../static/icon/area_chart.svg';
import BarChartIcon from '../../static/icon/bar_chart.svg';

const {
	blockPerHourSelector,
	blockPerMinSelector,
	blockPerDaySelector,
	blockAllSelector,
	currentChannelSelector,
	transactionPerHourSelector,
	transactionPerMinSelector,
	transactionPerDaySelector,
	transactionAllSelector
	
} = chartSelectors;

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		chart: {
			color: dark ? '#ffffff' : undefined,
			backgroundColor: dark ? '#453e68' : undefined
		}
	};
};

export class ChartStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			lineChartType: 'blocks',
			chartDataFilter: 'ALL', 
			chartData: {},
			sumCount: true
		};
	}

	componentDidMount() {
		const { currentChannel } = this.props;
		this.syncData(currentChannel);
		this.interVal = setInterval(() => {
			this.syncData(currentChannel);
		}, 60000);
	}

	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	syncData = currentChannel => {
		const {
			getBlocksPerHour,
			getBlocksPerMin,
			getBlocksAll,
			// getBlocksPerDay,
			getTransactionPerHour,
			getTransactionPerMin,
			getTransactionAll
		} = this.props;
		getBlocksAll(currentChannel);
		getBlocksPerMin(currentChannel);
		getBlocksPerHour(currentChannel);
		// getBlocksPerDay(currentChannel);
		getTransactionPerMin(currentChannel);
		getTransactionPerHour(currentChannel);
		getTransactionAll(currentChannel)
	};
	handleChartFilter = (item) => {
		const { currentChannel, getBlocksPerDay, getBlocksAll, getTransactionPerDay, getTransactionAll } = this.props;
		this.setState({ chartDataFilter: item })
		if(['1D', '7D'].includes(item) || item === this.state.chartDataFilter)
			return
		if(item === 'ALL'){
			getBlocksAll(currentChannel);
			getTransactionAll(currentChannel);
			return
		}
		var days
		switch(item) {
			case '1M':
				days = 30
				break
			case '3M':
				days = 90
				break
			case '1Y':
				days = 360
				break
			default: 
				days = 360
				break
		}
		getBlocksPerDay(currentChannel, days);
		getTransactionPerDay(currentChannel, days)
	}
	timeDataSetup = (chartData = []) => {
		let dataMax = 0;
		const displayData = chartData.map(data => {
			if (parseInt(data.count, 10) > dataMax) {
				dataMax = parseInt(data.count, 10);
			}

			return {
				datetime: moment(data.datetime)
					.tz(moment.tz.guess())
					.format('h:mm A'),
				count: data.count
			};
		});

		dataMax += 5;

		return {
			displayData,
			dataMax
		};
	};
	getChartOption = (chartData = []) => {
		const { sumCount } = this.state
		let options = {
			title: {
				text: ''
			},
			rangeSelector: {
				selected: 1
			},
			series: [{
				showInLegend: false,
				type: sumCount ? "area" : "column",
				fillColor: {
					linearGradient: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: 1
					},
					stops: [
						[0, Highcharts.getOptions().colors[0]],
						[1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
					]
				},
				plotOptions: {
					series: {
						fillOpacity: 0.2, // add an opacity for the fill color
					}
				},
				threshold: null
			}],
			credits: false,
			chart: {
				backgroundColor: this.props.mode === "light" ? "" : "#242729",
				type: 'line'
			},
			xAxis: {
				gridLineColor: this.props.mode === "light" ? "#F0F1F2" : "rgba(229, 233, 242, 0.1)",
				gridLineWidth: "1px"
			},
			yAxis: {
				title: "",
				gridLineColor: this.props.mode === "light" ? "#F0F1F2" : "rgba(229, 233, 242, 0.1)"
			}
		}
		let data = []
		let cat = []
		const chartRows = sumCount ? chartData : distructRows(chartData)
		chartRows.forEach(item => {
			data.push(parseInt(item.count))
			let time = new Date(item.datetime)
			cat.push(formatAMPM(time, this.state.chartDataFilter))
		})
		options.series[0].data = data;
		options.xAxis.categories = cat;
		return options
	}
	toggle = tab => {
		this.setState({
			activeTab: tab
		});
	};

	toggleSumCount = (e) => {
		this.setState({sumCount: e.target.checked})
	}

	render() {
		const { activeTab, sumCount } = this.state;
		const {
			blockPerHour,
			blockPerMin,
			blockAll,
			blockPerDay,
			transactionPerHour,
			transactionPerMin,
			transactionAll,
			transactionPerDay,
			classes,
		} = this.props;
		const listChartProps = ['1D', '7D', '1M', '3M', '1Y', 'ALL']
		const chartBlocksData = (() => {
			switch(this.state.chartDataFilter) {
				case "1D":
					return blockPerMin
				case "7D":
					return blockPerHour
				case "ALL":
					return blockAll
				default:
					return blockPerDay
			}
		})()
		const chartTransData = (() => {
			switch(this.state.chartDataFilter) {
				case "1D": {
					return transactionPerMin
				}
				case "7D":
					return transactionPerHour
				case "ALL": {
					return transactionAll
				}
				default:
					return transactionPerDay
			}
		})()
		return (
			<div className={classes.chart + " chart-stats-container"}>
				<div className="chart-top-row">
					<div className="top-chart-left">
						<div onClick={() => { 
								this.setState({ lineChartType: "blocks" })
								this.toggle('1')
							}} 
							style={{ color: this.state.lineChartType === "blocks" ? "#096AF5" : "" }}>Blocks</div>
						<div onClick={() => {
								this.setState({ lineChartType: "trans" })
								this.toggle('2')
							}} style={{ color: this.state.lineChartType === "trans" ? "#096AF5" : "" }}>Transactions</div>
						<div className="switch-chart">
							<img src={BarChartIcon} alt="bar" />
							<Switch checked={sumCount} onChange={this.toggleSumCount} color="default" />
							<img src={AreaChartIcon} alt="area" />
						</div>
					</div>
					<div className="top-chart-right">
						{listChartProps.map(item => {
							return <div className={`chart-props ${this.state.chartDataFilter === item ? "chart-props-active" : ""}`} onClick={() => this.handleChartFilter(item)}>{item}</div>
						})}
					</div>
				</div>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<HighchartsReact
							highcharts={Highcharts}
							options={this.getChartOption(chartBlocksData)}
						/>
					</TabPane>
					<TabPane tabId="2">
						<HighchartsReact
							highcharts={Highcharts}
							options={this.getChartOption(chartTransData)}
						/>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

ChartStats.propTypes = {
	blockPerHour: blockPerHourType.isRequired,
	blockPerMin: blockPerMinType.isRequired,
	blockPerDay: blockPerDayType.isRequired,
	blockAllType: blockAllType.isRequired,
	currentChannel: currentChannelType.isRequired,
	getBlocksPerHour: getBlocksPerHourType.isRequired,
	getBlocksPerMin: getBlocksPerMinType.isRequired,
	getTransactionPerHour: getTransactionPerHourType.isRequired,
	getTransactionPerMin: getTransactionPerMinType.isRequired,
	transactionPerHour: transactionPerHourType.isRequired,
	transactionPerMin: transactionPerMinType.isRequired,
	transactionPerDay: transactionPerDayType.isRequired,
	transactionAll: transactionAllType.isRequired
};

export default compose(
	withStyles(styles),
	connect(
		state => ({
			blockPerHour: blockPerHourSelector(state),
			blockPerMin: blockPerMinSelector(state),
			blockPerDay: blockPerDaySelector(state),
			blockAll: blockAllSelector(state),
			transactionPerHour: transactionPerHourSelector(state),
			transactionPerMin: transactionPerMinSelector(state),
			transactionPerDay: transactionPerDaySelector(state),
			transactionAll: transactionAllSelector(state),
			currentChannel: currentChannelSelector(state)
		}),
		{
			getBlocksPerHour: chartOperations.blockPerHour,
			getBlocksPerMin: chartOperations.blockPerMin,
			getBlocksPerDay: chartOperations.blockPerDay,
			getBlocksAll: chartOperations.blockAll,
			getTransactionPerHour: chartOperations.transactionPerHour,
			getTransactionPerMin: chartOperations.transactionPerMin,
			getTransactionPerDay: chartOperations.transactionPerDay,
			getTransactionAll: chartOperations.transactionAll,
		}
	)
)(ChartStats);

function formatAMPM(date, type) {
	var hours = date.getHours();
	var minutes = date.getMinutes();
	var ampm = hours >= 12 ? 'pm' : 'am';
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes;
	var strTime;
	switch(type) {
		case '1D':
			strTime = hours + ':' + minutes + ' ' + ampm;
			break;
		case '7D':
			strTime = hours + ':' + minutes + ' ' + ampm;
			break;
		default: 
			strTime = date.toLocaleDateString("en-US");
			break;
	}
	return strTime;
}
