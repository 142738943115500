/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';
import Timeago from 'react-timeago';
import ReactTable from '../Styled/Table';
import iCopy from './../../static/icon/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import {
	chartOperations,
	chartSelectors
} from './../../../src/state/redux/charts';
import { tableOperations, tableSelectors } from '../../state/redux/tables';

const PAGE_SIZE = 10;
const {
	transactionListSelector,
} = tableSelectors;
const { currentChannelSelector, blockNotificationsSelector } = chartSelectors;
export class TimelineStream extends Component {
	constructor(props) {
		super(props);
	}

	reactTableSetup = () => {
		if (this.props.type === "blocks")
			return [
				{
					Header: 'Block',
					accessor: 'blocknum',
					width: 92
				},
				{
					Header: 'Channel Name',
					accessor: 'channelname',
					Cell: props => <b>{props.value}</b>,
					width: 160
				},
				{
					Header: 'Data Hash',
					accessor: 'datahash',
					Cell: props => {
						const linkToDetail =
							window.location.origin + '#/block/' + props.original.datahash;
						return (
							<div className="cell-copy">
								<div className="btn-link">
									<a href={linkToDetail}>
										{props.value}
									</a>
								</div>
								<div className="btn-copy">
									<CopyToClipboard text={linkToDetail}>
										<img src={iCopy} alt="copy" />
									</CopyToClipboard>
								</div>
							</div>
						);
					},
					minWidth: 130
				},
				{
					Header: 'Number (TX)',
					accessor: 'txcount',
					width: 135
				},
				{
					Header: () => <div className="text-right">Created at</div>,
					accessor: 'createdt',
					className: 'text-right cell-time',
					Cell: row => formatDate(row.value),
					width: 150
				}
			];
		if (this.props.type === "trans")
			return [
				{
					Header: 'TxnHash',
					accessor: 'txhash',
					Cell: props => {
						const linkToDetail =
							window.location.origin + '#/tx/' + props.value;
						return (
							<div className="cell-copy">
								<div className="btn-link text-in-blue">
									<a href={linkToDetail}>
										{props.value}
									</a>
								</div>
								<div className="btn-copy">
									<CopyToClipboard text={linkToDetail}>
										<img src={iCopy} alt="copy" />
									</CopyToClipboard>
								</div>
							</div>
						);
					},
					minWidth: 110
				},
				{
					Header: 'Block',
					accessor: 'blockid',
					className: "text-left",
					Cell: props => <b>{props.value}</b>,
					width: 160
				},
				// {
				// 	Header: 'IntegraID',
				// 	accessor: 'id',
				// 	width: 160,
				// 	className: "text-left"
				// },

				{
					Header: () => <div className="text-right">Created Date</div>,
					accessor: 'createdt',
					className: 'text-center cell-time',
					Cell: row => formatDate(row.value),
					width: 150
				},
				{
					Header: 'Identity Type',
					accessor: 'type',
					width: 200
				},
			];
	}

	loadPage = page =>
		this.props.getBlockNotificationsPage({
			channels: this.props.currentChannel,
			page: page
		});

	onChangePagination = async (e, page) => {
		await this.loadPage(page);
		this.setState({ page: page });
	};

	// Init
	componentDidMount = async () => {
		await this.loadPage(this.props.blockList.page);

		this.interVal = setInterval(() => {
			this.loadPage(this.props.blockList.page);
		}, 3000);
	};

	// Out
	componentWillUnmount() {
		clearInterval(this.interVal);
	}

	render() {
		const { blockList } = this.props;
		return (
			<>
				<ReactTable
					data={this.props.collapse ? this.props.data : blockList.data}
					columns={this.reactTableSetup()}
					className="full header-align-left timeline-block"
					minRows={0}
					sortable={false}
					showPagination={false}
				/>
				{!this.props.collapse && <Pagination
					className="pagination_mui_custom"
					page={blockList?.page}
					count={Math.ceil(blockList?.total / PAGE_SIZE)}
					variant="outlined"
					shape="rounded"
					onChange={this.onChangePagination}
					renderItem={item => {
						if (item.type.includes('ellipsis')) {
							return <div className="pageButton pageButton--ellipsis">...</div>;
						} else {
							return (
								<PaginationItem
									className={
										item.selected
											? 'pageButton pageButton--active'
											: 'pageButton'
									}
									{...item}
								/>
							);
						}
					}}
				/>}
			</>
		);
	}
}

export default connect(
	state => ({
		currentChannel: currentChannelSelector(state),
		blockList: blockNotificationsSelector(state),
		transactionList: transactionListSelector(state),
	}),
	{
		getBlockNotificationsPage: chartOperations.blockNotificationsPage
	}
)(TimelineStream);

const formatDate = (data) => {
	var date = new Date(data)
	return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
}
