/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { combineReducers } from 'redux';
import types from './types';

/* Reducers for Dashboard Charts */
const initialState = {};

const blockPerHourReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_CHART_HOUR: {
			return {
				rows: action.payload.blockPerHour.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};
const errorMessageReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.ERROR_MESSAGE: {
			return {
				error: action.payload
			};
		}
		default: {
			return state;
		}
	}
};

const blockPerMinReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_CHART_MIN: {
			return {
				rows: action.payload.blockPerMin.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};
const blockPerDayReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_CHART_DAY: {
			return {
				rows: action.payload.blockPerDay.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const blockAllReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_CHART_ALL: {
			return {
				rows: action.payload.blockAll.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const channelListReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.CHANNEL_LIST: {
			return {
				list: action.payload.channels,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const channelReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.CHANNEL: {
			return action.payload.channel;
		}
		case types.CHANGE_CHANNEL: {
			return action.payload.channel;
		}
		default: {
			return state;
		}
	}
};

const dashStatsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.DASHBOARD_STATS: {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};
const blockActivityReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_ACTIVITY: {
			return {
				rows: action.payload.row,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};
const blockNotificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.BLOCK_NOTIFICATIONS: {
			return {
				data: action.payload.row,
				total: Number(action.payload.total),
				page: Number(action.payload.page),
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};
const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.NOTIFICATION_LOAD: {
			return action.payload.notification;
		}
		default: {
			return state;
		}
	}
};

const peerStatusReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.PEER_STATUS: {
			return {
				list: action.payload.peers,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const transactionByOrgReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TRANSACTION_CHART_ORG: {
			return {
				rows: action.payload.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const transactionPerHourReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TRANSACTION_CHART_HOUR: {
			return {
				rows: action.payload.transactionPerHour.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const transactionPerMinReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TRANSACTION_CHART_MIN: {
			return {
				rows: action.payload.transactionPerMin.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const transactionPerDayReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TRANSACTION_CHART_DAY: {
			return {
				rows: action.payload.transactionPerDay.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const transactionAllReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TRANSACTION_CHART_ALL: {
			return {
				rows: action.payload.transactionAll.rows,
				loaded: true,
				errors: action.errors
			};
		}
		default: {
			return state;
		}
	}
};

const reducer = combineReducers({
	blockPerHour: blockPerHourReducer,
	blockPerMin: blockPerMinReducer,
	blockPerDay: blockPerDayReducer,
	blockAll: blockAllReducer,
	channel: channelReducer,
	channelList: channelListReducer,
	dashStats: dashStatsReducer,
	notification: notificationReducer,
	peerStatus: peerStatusReducer,
	transactionByOrg: transactionByOrgReducer,
	transactionPerHour: transactionPerHourReducer,
	transactionPerMin: transactionPerMinReducer,
	transactionPerDay: transactionPerDayReducer,
	transactionAll: transactionAllReducer,
	errorMessage: errorMessageReducer,
	blockActivity: blockActivityReducer,
	blockNotifications: blockNotificationsReducer
});

export default reducer;
