/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import OrgPieChart from '../Charts/OrgPieChart';
import {
   blockListType,
   channelsType,
   dashStatsType,
   peerStatusType,
   transactionByOrgType
} from '../types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { themeSelectors } from '../../state/redux/theme';
import TimelineStream from '../Lists/TimelineStream';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ChartStart from '../Charts/ChartStats';

import FilterIcon from '../../assets/icons/codicon_list-filter-icon.svg';
import SearchIcon from '../../assets/icons/icon-search.svg';
import { tableOperations, tableSelectors } from '../../state/redux/tables';
import { TabContent, TabPane } from 'reactstrap';

const { modeSelector } = themeSelectors;

/* istanbul ignore next */
const {
   transactionListSelector,
   blockListSearchSelector,
} = tableSelectors;

const styles = theme => {
   const { type } = theme.palette;
   const dark = type === 'dark';
   return {
      view: {
         maxWidth: theme.bg.size,
         margin: 'auto'
      },
      blocks: {
         height: 175,
         marginBottom: 20,
         // backgroundColor: dark ? '#453e68' : '#ffffff',
         boxShadow: dark ? '1px 2px 2px rgb(215, 247, 247)' : undefined
      },
      count: {
         marginTop: '55%',
         color: dark ? '#ffffff' : undefined
      },
      statistic: {
         display: 'block',
         float: 'left',
         height: '100%',
         width: '25%',
         textAlign: 'center',
         fontSize: '18pt',
         color: dark ? '#ffffff' : '#000000'
      },
      vdivide: {
         '&::after': {
            borderRight: `2px ${dark ? 'rgb(40, 36, 61)' : '#dff1fe'} solid`,
            display: 'block',
            height: '45%',
            bottom: '55%',
            content: "' '",
            position: 'relative'
         }
      },
      avatar: {
         justifyContent: 'center',
         marginLeft: '60%',
         marginTop: '65%'
      },
      node: {
         color: dark ? '#183a37' : '#21295c',
         backgroundColor: dark ? 'rgb(104, 247, 235)' : '#858aa6'
      },
      block: {
         color: dark ? '#1f1a33' : '#004d6b',
         backgroundColor: dark ? 'rgb(106, 156, 248)' : '#b9d6e1'
      },
      chaincode: {
         color: dark ? 'rgb(121, 83, 109)' : '#407b20',
         backgroundColor: dark ? 'rgb(247, 205, 234)' : '#d0ecda'
      },
      transaction: {
         color: dark ? 'rgb(216, 142, 4)' : '#ffa686',
         backgroundColor: dark ? 'rgb(252, 224, 174)' : '#ffeed8'
      },
      center: {
         textAlign: 'center'
      }
   };
};
export class DashboardView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         hasDbError: false,
         lineChartType: "blocks",
         chartProps: "1Y",
         typeSearch: "creator_msp_id",
         search: "",
         tableTab: "blocks"
      };
   }
   componentWillMount() {
      const {
         blockList,
         dashStats,
         peerStatus,
         transactionByOrg,
         blockActivity
      } = this.props;
      if (
         blockList === undefined ||
         dashStats === undefined ||
         // peerStatus === undefined ||
         // blockActivity === undefined ||
         transactionByOrg === undefined
      ) {
         this.setState({ hasDbError: true });
      }
   }

   handleSearch = (e) => {
      // Prevent browser default
      e && e.preventDefault();

      if (this.state.search === '')
         return
      window.location.href = `/#/transactions?type=${this.state.typeSearch}&q=${encodeURIComponent(this.state.search)}`;
   }
   render() {
      const { dashStats, transactionByOrg, blockActivity, transactionList, blockList } = this.props;
      const { hasDbError } = this.state;
      const { mode } = this.props
      if (hasDbError) {
         return (
            <div
               style={{
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
               }}
            >
               <h1>
                  Please verify your network configuration, database configuration and try
                  again
               </h1>
            </div>
         );
      }
      const { classes } = this.props;
      return (
         <div className={`dashboard-view-container`}>
            <div className={classes.view}>
               <div className="search-box-holder">
                  <div className="filter-box">
                     <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                        <Select
                           labelId="demo-simple-select-outlined-label"
                           id="demo-simple-select-outlined"
                           value={this.state.typeSearch}
                           onChange={(event) => this.setState({ typeSearch: event.target.value })}

                        >
                           <MenuItem value={"creator_msp_id"}>Creator</MenuItem>
                           <MenuItem value={"channelname"}>Channel name</MenuItem>
                           <MenuItem value={"txhash"}>Tx id</MenuItem>
                           <MenuItem value={"type"}>Type</MenuItem>
                           <MenuItem value={"chaincodename"}>Chaincode</MenuItem>
                           <MenuItem value={"createdt"}>Timestamp</MenuItem>
                           <MenuItem value={"integra_id"}>Integra ID</MenuItem>
                        </Select>
                     </FormControl>
                  </div>
                  <div className="search-box">
                     <form onSubmit={this.handleSearch}>
                        <input
                           onChange={event => this.setState({ search: event.target.value })}
                           placeholder="Search by Creator/Channel name/Tx id/Type/Chaincode/Timestamp/integra id"
                        />
                        <span className="search-btn" onClick={() => { this.handleSearch() }}>
                           <img src={SearchIcon} />
                        </span>
                     </form>
                  </div>
               </div>
               <div className="data-sythetic-block">
                  <div className="data-sythetic-card">
                     <div className="data-sythetic-path icon">
                        <FontAwesome width="38px" height="38px" name="cube" />
                     </div>
                     <div className="data-sythetic-path count" sm="4">
                        {dashStats.latestBlock}
                     </div>
                     Blocks
                  </div>
                  <div className="data-sythetic-card">
                     <div className="data-sythetic-path icon">
                        <FontAwesome name="list-alt" />
                     </div>
                     <div className="data-sythetic-path count">
                        {dashStats.txCount}
                     </div>
                     Transactions
                  </div>
                  <div className="data-sythetic-card">
                     <div className="data-sythetic-path icon">
                        <FontAwesome name="users" />
                     </div>
                     <div className="data-sythetic-path count">
                        {dashStats.peerCount}
                     </div>
                     Nodes
                  </div>
                  <div className="data-sythetic-card">
                     <div className="data-sythetic-path icon">
                        <FontAwesome name="handshake-o" />
                     </div>
                     <div className="data-sythetic-path count">
                        {dashStats.chaincodeCount}
                     </div>
                     Chaincodes
                  </div>
               </div>
               <div className="trans-chart-block">
                  <div>
                     <ChartStart mode={mode} />
                  </div>
               </div>
               <div className="trans-table-holder">
                  <div className="trans-header">
                     <div className={`block-header ${this.state.tableTab === "blocks" ? "active" : ""}`} onClick={() => this.setState({ tableTab: "blocks" })}>Blocks</div>
                     <div className={`trans-header ${this.state.tableTab === "trans" ? "active" : ""}`} onClick={() => this.setState({ tableTab: "trans" })}>Transactions</div>
                  </div>
                  <div className="filter-row">
                     <div className="trans-table-filter-txt">
                        <img className="filter-icon" src={FilterIcon} />
                        Latest 5 from a total {this.state.tableTab === "blocks" ? "blocks" : "transactions"} 
                     </div>
                  </div>
                  <div>
                     <TabContent activeTab={this.state.tableTab}>
                        <TabPane tabId="trans">
                           <div className="section">
                              <TimelineStream type="trans" collapse="true" data={transactionList.slice(0, 5)} />
                           </div>
                        </TabPane>
                        <TabPane tabId="blocks">
                           <div className="section">
                              <TimelineStream type="blocks" collapse="true" data={blockList.slice(0, 5)} />
                           </div>
                        </TabPane>
                     </TabContent>
                  </div>
               </div>

               <Row>
                  <Col lg={6} className="d-flex" >
                     <div className="section">
                        <TimelineStream type="blocks" />
                     </div>
                  </Col>
                  <Col lg={6} className="d-flex" >
                     <div className="section">
                        <div className="section-title">Transactions by Organization</div>
                        <div className="section-break"></div>
                        <OrgPieChart transactionByOrg={transactionByOrg} onRedirect={this.props.history}/>
                     </div>
                  </Col>
               </Row>
            </div>
         </div>
      );
   }
}

DashboardView.propTypes = {
   blockList: blockListType.isRequired,
   dashStats: dashStatsType.isRequired,
   channels: channelsType.isRequired,
   peerStatus: peerStatusType.isRequired,
   transactionByOrg: transactionByOrgType.isRequired
};

// export default withStyles(styles)(DashboardView);
export default compose(
   withStyles(styles),
   connect(
      state => ({
         // error: errorMessageSelector(state),
         mode: modeSelector(state),
         transactionList: transactionListSelector(state),
         blockListSearch: blockListSearchSelector(state),
      }))
)(DashboardView);
