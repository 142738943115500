/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from 'react';
import View from '../Styled/View';
import Holders from '../Lists/Holders';
import { peerListType } from '../types';

export const HoldersView = ({ peerList }) => (
  <View>
    <Holders peerList={peerList} />
  </View>
);

HoldersView.propTypes = {
  peerList: peerListType.isRequired,
};

export default HoldersView;
