/**
 *    SPDX-License-Identifier: Apache-2.0
 */

export const blockPerHourSelector = state => state.charts.blockPerHour.rows;
export const blockPerDaySelector = state => state.charts.blockPerDay.rows;
// export const blockChartData = state => state.charts.blockChartData;
// export const transChartData = state => state.charts.transChartData;
export const blockAllSelector = state => state.charts.blockAll.rows;
export const blockPerMinSelector = state => state.charts.blockPerMin.rows;
export const channelListSelector = state => state.charts.channelList.list;
export const currentChannelSelector = state => state.charts.channel.currentChannel;
export const dashStatsSelector = state => state.charts.dashStats;
export const notificationSelector = state => state.charts.notification;
export const peerStatusSelector = state => state.charts.peerStatus.list;
export const transactionByOrgSelector = state => state.charts.transactionByOrg.rows;
export const transactionPerHourSelector = state => state.charts.transactionPerHour.rows;
export const transactionPerMinSelector = state => state.charts.transactionPerMin.rows;
export const transactionPerDaySelector = state => state.charts.transactionPerDay.rows;
export const transactionAllSelector = state => state.charts.transactionAll.rows;
export const errorMessageSelector = state => state.charts.errorMessage.error;
export const blockActivitySelector = state => state.charts.blockActivity.rows;
export const blockNotificationsSelector = state => state.charts.blockNotifications;
