/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import actions from './actions';
import { get } from '../../../services/request';
import { responseToIncrementalData } from '../../../utils';

/* istanbul ignore next */
const blockPerHour = channel => dispatch =>
	get(`/api/blocksByHour/${channel}/7`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getBlockPerHour(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const blockPerMin = channel => dispatch =>
	get(`/api/blocksByMinute/${channel}/24`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getBlockPerMin(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

const blockPerDay = (channel, days) => dispatch =>
	get(`/api/blocksByDay/${channel}/${days}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getBlockPerDay(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

const blockAll = channel => dispatch => {
	get(`/api/getAllBlocks/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getBlockAll(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});
};
/* istanbul ignore next */
const changeChannel = channel => dispatch =>
	get(`/api/changeChannel/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.updateChannel(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const channel = () => dispatch =>
	get('/api/curChannel')
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getChannel(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const channelList = () => dispatch =>
	get('/api/channels')
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getChannelList(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const dashStats = channel => dispatch =>
	get(`/api/status/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getDashStats(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const blockActivity = channel => dispatch =>
	get(`/api/blockActivity/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getBlockActivity(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

const blockNotificationsPage =
	({ channels, limit = 10, page = 1 }) =>
		dispatch =>
			get(`/api/blockActivity/${channels}/${page}/${limit}`)
				.then(resp => {
					if (resp.status === 500) {
						dispatch(
							actions.getErroMessage(
								'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
							)
						);
					} else if (resp.status === 400) {
						dispatch(actions.getErroMessage(resp.error));
					} else {
						dispatch(actions.getBlockNotifications({ ...resp, page: page }));
					}
				})
				.catch(error => {
					console.error(error);
				});

/* istanbul ignore next */
const notification = notification => dispatch => {
	const notify = JSON.parse(notification);
	dispatch(actions.getNotification(notify));
};

/* istanbul ignore next */
const peerStatus = channel => dispatch =>
	get(`/api/peersStatus/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getPeerStatus(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const transactionByOrg = channel => dispatch =>
	get(`/api/txByOrg/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				dispatch(actions.getTransactionByOrg(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const transactionPerHour = channel => dispatch =>
	get(`/api/txByHour/${channel}/7`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getTransactionPerHour(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

/* istanbul ignore next */
const transactionPerMin = channel => dispatch =>
	get(`/api/txByMinute/${channel}/24`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getTransactionPerMin(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

const transactionPerDay = (channel, days) => dispatch =>
	get(`/api/txByDay/${channel}/${days}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getTransactionPerDay(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

const transactionAll = channel => dispatch =>
	get(`/api/getAllTx/${channel}`)
		.then(resp => {
			if (resp.status === 500) {
				dispatch(
					actions.getErroMessage(
						'500 Internal Server Error: The server has encountered an internal error and unable to complete your request'
					)
				);
			} else if (resp.status === 400) {
				dispatch(actions.getErroMessage(resp.error));
			} else {
				responseToIncrementalData(resp);
				dispatch(actions.getTransactionAll(resp));
			}
		})
		.catch(error => {
			console.error(error);
		});

export default {
	blockPerHour,
	blockPerMin,
	blockPerDay,
	blockAll,
	transactionPerHour,
	transactionPerMin,
	transactionPerDay,
	transactionAll,
	transactionByOrg,
	notification,
	dashStats,
	channel,
	channelList,
	changeChannel,
	peerStatus,
	blockActivity,
	blockNotificationsPage
};
