import React, { useState } from "react";
import { esMill } from "@react-jvectormap/spain";
import { VectorMap } from "react-jvectormap";
import "./Map.css";

const mapData = {
  //DE: 0
};

const handleClick = (e, countryCode) => {
  //console.log(countryCode);
};

const MapChart = () => {
  return (
    <React.Fragment>
      <VectorMap
        map={"world_mill"}
        //backgroundColor="transparent" //change it to ocean blue: #0077be
        backgroundColor="#293948"
        zoomOnScroll={true}
        markers={[
          { latLng: [52.5, 13.39], name: "Berlin" },
          { latLng: [53.56, 10.0], name: "Hamburg" },
          { latLng: [48.13, 11.56], name: "Munich" },
          { latLng: [50.95, 6.96], name: "Cologne" },
          { latLng: [50.11, 8.68], name: "Frankfurt am Main" },
          { latLng: [48.77, 9.17], name: "Stuttgart" },
          { latLng: [51.23, 6.78], name: "Düsseldorf" },
          { latLng: [51.51, 7.46], name: "Dortmund" },
          { latLng: [51.45, 7.01], name: "Essen" },
          { latLng: [53.07, 8.8], name: "Bremen" },
          { latLng: [38.9, -98.45], name: "Test country" },
          { latLng: [31.8, -68.5], name: "country 1" },
          { latLng: [18.9, -48.45], name: "Test country 2" },
          { latLng: [68.9, -38.85], name: "Test country 3" },
          { latLng: [28.9, -98.25], name: "Test country 4" },
        ]}
        markerStyle={{
          initial: {
            fill: "#c453ea",
          },
        }}
        containerStyle={{
          width: "100%",
          height: "500px",
        }}
        onRegionClick={handleClick} //gets the country code
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#fff",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
          selected: {
           // fill: "#0A6ED1", //color for the clicked country
            fill: '#666666'
          },
          selectedHover: {},
        }}
        regionsSelectable={true}
        series={{
          regions: [
            {
              values: mapData, //this is your data
              scale: ["#146804", "#ff0000"], //your color game's here
              normalizeFunction: "polynomial",
            },
          ],
        }}
      />
    </React.Fragment>
  );
};

export default MapChart;
