/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import HeaderPublic from '../HeaderPublic';
import Footer from '../Footer';
import { authSelectors, authOperations } from '../../state/redux/auth';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import iCopy from './../../static/icon/copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';

moment.locale('de');

const { authSelector } = authSelectors;
export class BlockDetail extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const blockHash = this.props.match.params.id;
		this.props.getDetail(blockHash).then(res => {
			if (res?.row?.length > 0) {
				this.setState(res.row[0]);
			}
		});
	}

	render() {
		const row = this.state;
		const { auth } = this.props;

		if (!row) return <></>;

		return (
			<>
				{!auth && <HeaderPublic />}
				<div className="main">
					<div className="detail-box">
						<div className="box-title">
							Block<span>#{row.blocknum}</span>
						</div>
						<div className="box-title-sub">Overview</div>
						<div className="box-table">
							<Row>
								<Col className="cell" md={3} xs={5}>
									Channel Name:
								</Col>
								<Col className="cell">{row.channelname}</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Block Number:
								</Col>
								<Col className="cell">{row.blocknum}</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Created at:
								</Col>
								<Col className="cell">
									{moment(row.createdt)
										.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
										.format('DD/MM/YYYY - hh:mm:ss')}
								</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Number of Transactions:
								</Col>
								<Col className="cell">{row.txcount}</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Block Hash
								</Col>
								<Col className="cell">
									<div className="text">{row.blockhash}</div>
									<div className="btn-copy">
										<CopyToClipboard text={row.blockhash}>
											<img src={iCopy} alt="copy" />
										</CopyToClipboard>
									</div>
								</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Data Hash
								</Col>
								<Col className="cell">
									<div className="text">{row.datahash}</div>
									<div className="btn-copy">
										<CopyToClipboard text={row.datahash}>
											<img src={iCopy} alt="copy" />
										</CopyToClipboard>
									</div>
								</Col>
							</Row>
							<Row>
								<Col className="cell" md={3} xs={5}>
									Pre Hash
								</Col>
								<Col className="cell">
									<div className="text">{row.prehash}</div>
									<div className="btn-copy">
										<CopyToClipboard text={row.prehash}>
											<img src={iCopy} alt="copy" />
										</CopyToClipboard>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				{!auth && <Footer />}
			</>
		);
	}
}

export default compose(
	connect(
		state => ({
			auth: authSelector(state)
		}),
		{
			getDetail: authOperations.blockDetail
		}
	)
)(BlockDetail);
