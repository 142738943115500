/**
 *    SPDX-License-Identifier: Apache-2.0
 */
/* eslint-disable */
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FontAwesome from 'react-fontawesome';
import Timeago from 'react-timeago';


export class NotificationsPanel extends Component {
	/* istanbul ignore next */
	avatarIcon = (type, classes) => {
		switch (type) {
			case 'block':
				return (
					<Avatar className="item-icon">
						<FontAwesome name="cube" />{' '}
					</Avatar>
				);
			default:
				return (
					<Avatar className="item-icon">
						<FontAwesome name="exclamation" />{' '}
					</Avatar>
				);
		}
	};

	/* istanbul ignore next */
	render() {
		let { classes, notifications } = this.props;

		// Empty notification
		if (notifications.length === 0) {
			return (
				<div className="no-data">
					No notifications
				</div>
			);
		}

		/* istanbul ignore next */
		return (
			<List component="nav" className="noti-list">
				{notifications.map((notify, index) => (
					<React.Fragment key={index}>
						<ListItem button className="item">
							{this.avatarIcon(notify.type, classes)}
							<ListItemText
								className="item-text"
								primary={notify.title}
								secondary={notify.message}
							/>
							<Timeago
								className="item-time"
								date={notify.time}
								live={false}
								minPeriod={60}
							/>
						</ListItem>
					</React.Fragment>
				))}
			</List>
		);
	}
}

export default NotificationsPanel;
