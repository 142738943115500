/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import types from "./types";

const initialState = {
  mode: "light",
  modeType: "test",
};

/* Reducers for Dashboard Charts */
const themeReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.CHANGE_THEME: {
      return {
        ...state,
        mode: action.payload.mode,
      };
    }

    case types.CHANGE_MODE_TYPE: {
      return {
        ...state,
        modeType: action.payload.mode,
      };
    }

    default: {
      return state;
    }
  }
};

export default themeReducer;
