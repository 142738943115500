/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { useEffect, useState } from "react";
import matchSorter from "match-sorter";
import ReactTable from "../Styled/Table";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { tableOperations, tableSelectors } from "../../state/redux/tables";
import { withStyles } from "@material-ui/core/styles";
import { Link, Redirect } from 'react-router-dom'

/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === 'dark';
	return {
		partialHash: {
			textAlign: 'center',
			position: 'relative !important',
			'&:hover $fullHash': {
				display: 'block',
				position: 'absolute !important',
				padding: '4px 4px',
				backgroundColor: dark ? '#5e558e' : '#000000',
				marginTop: -30,
				// marginLeft: -215,
				borderRadius: 8,
				color: '#ffffff',
				opacity: dark ? 1 : undefined
			},
			'&:hover $lastFullHash': {
				display: 'block',
				position: 'absolute !important',
				padding: '4px 4px',
				backgroundColor: dark ? '#5e558e' : '#000000',
				marginTop: -30,
				// marginLeft: -415,
				borderRadius: 8,
				color: '#ffffff',
				opacity: dark ? 1 : undefined,
        textAlign: 'center',
			}
		},
		fullHash: {
			display: 'none'
		},
		filter: {
			width: '100%',
			textAlign: 'center',
			margin: '0px !important'
		},
	};
};
const {
    holderListSearch
  } = tableOperations;
  
const Holders = (props) => {
    const { holderListSearch, holderData, classes } = props

  useEffect(() => {
    holderListSearch()
  },[])

  const onRedirect = (integraId) => {
   // alert(integraId)
  }

  const columnHeaders = [
    {
      Header: "Integra Id",
      accessor: "integraId",
      Cell: row => (
				<span>
          <Link to={`/transactions?type=integra_id&q=40865eaf-d4b3-0154-a6c8-29f635af2145`} >
					<a
						data-command="block-partial-hash"
						className={classes.partialHash}
						onClick={() => onRedirect(row.value)}
						//href="#/transactions?type=integra_id&q=40865eaf-d4b3-0154-a6c8-29f635af2145"
					>
						<div className={classes.fullHash} id="showTransactionId">
							{row.row && row.row.integraId}
						</div>{' '}
						{row.row && row.row.integraId}
					</a>{' '}
          </Link>
				</span>
			),

      filterMethod: (filter, rows) =>{
        return matchSorter(
          rows,
          filter.value,
          { keys: ["integraId"] },
          { threshold: matchSorter.rankings.SIMPLEMATCH }
        )},
      filterAll: true,
    },
    {
      Header: "Amount",
      accessor: "tokenAmount",
      filterMethod: (filter, rows) =>{
         return matchSorter(
          rows,
          filter.value,
          { keys: ["tokenAmount"] },
          { threshold: matchSorter.rankings.SIMPLEMATCH }
        )},
      filterAll: true,
    },
    {
      Header: "Tx Id",
      accessor: "transactionId",
      filterMethod: (filter, rows) =>
        matchSorter(
          rows,
          filter.value,
          { keys: ["transactionId]"] },
          { threshold: matchSorter.rankings.SIMPLEMATCH }
        ),
      filterAll: true,
    },
    {
      Header: "Timestamp",
      accessor: "creationDate",
      filterMethod: (filter, rows) =>
        matchSorter(
          rows,
          filter.value,
          { keys: ["creationDate"] },
          { threshold: matchSorter.rankings.SIMPLEMATCH }
        ),
      filterAll: true,
    }  
  ];

  return (
    <div>
      <ReactTable
        data={holderData}
        columns={columnHeaders}
        defaultPageSize={5}
        filterable
        minRows={0}
        //showPagination={!(peerList.length < 5)}
      />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    holderData: state.tables.holderData.holderList,
    loaded: state.tables.holderData.loaded,
  };
};

export default compose(
  withStyles(styles),
	connect(
		mapStateToProps,
		{
			holderListSearch: holderListSearch,
		}
	)
)(Holders);