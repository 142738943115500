/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Main from "../Main";
import Header from "../Header";
import Footer from "../Footer";
import LandingPage from "../View/LandingPage";
import ErrorMessage from "../ErrorMessage";
import { chartSelectors } from "../../state/redux/charts";
import { themeSelectors, themeActions } from "../../state/redux/theme";
import { authSelectors } from "../../state/redux/auth";
import { checkEmbedRoute } from "./../../helpers/checkEmbedRoute";

import Login from "../Login";
import BlockDetail from "../BlockDetail";

import Private from "../Route";
import TransactionDetail from "../TransactionDetail";

/* istanbul ignore next */
const styles = (theme) => {
  return {
    app: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      "& ol, & ul": {
        listStyle: "none",
      },
    },
  };
};

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isHeaderVisible: true,
    };
  }

  componentDidMount() {
    const isEmbeded = checkEmbedRoute();
    if (isEmbeded === 'true') { 
      this.setState({ isHeaderVisible: false });
    }
  }

  /* istanbul ignore next */
  updateLoadStatus = () => {
    this.setState({ loading: false });
  };

  /* istanbul ignore next */
  refreshComponent = (mode) => {
    this.props.changeTheme(mode);
  };

  /* istanbul ignore next */
  render() {
    const { auth, mode, error } = this.props;
    const { loading, isHeaderVisible } = this.state;
    // Set theme
    document.querySelector("html").setAttribute("theme", mode);
    // Check authentication
    if (auth && loading && isHeaderVisible) {
      return (
        <LandingPage
          updateLoadStatus={this.updateLoadStatus}
          isDark={mode === "dark"}
        />
      );
    }

    return (
      <>
      <Router>
        {auth && isHeaderVisible && <Header refresh={this.refreshComponent} />}
        {error && <ErrorMessage message={error} />}
        
          <Switch>
            <Route
              exact
              path="/login"
              render={(routeprops) => <Login {...routeprops} />}
            />
            <Route
              exact
              path="/block/:id"
              render={(routeprops) => <BlockDetail {...routeprops} />}
            />
            <Route
              exact
              path="/tx/:id"
              render={(routeprops) => <TransactionDetail {...routeprops} />}
            />
            <Private
              path="/"
              render={(routeprops) => <Main {...routeprops} />}
            />
          </Switch>
        </Router>
        {auth && isHeaderVisible && <Footer />}
      </>
    );
  }
}

const { modeSelector } = themeSelectors;
const { changeTheme } = themeActions;
const { errorMessageSelector } = chartSelectors;
const { authSelector } = authSelectors;

/* istanbul ignore next */
export default compose(
  withStyles(styles),
  connect(
    (state) => ({
      error: errorMessageSelector(state),
      mode: modeSelector(state),
      auth: authSelector(state),
    }),
    { changeTheme }
  )
)(App);
