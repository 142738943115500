/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import agent from "superagent";
import Auth from "../state/Auth";
const superagentAbsolute = require("superagent-absolute");
const agentAbsolute = agent.agent();

export const post = (uri, payload) =>
  new Promise((resolve, reject) => {
     let request = getDomain();
     request
    //agent
      .post(uri)
      .send(payload)
      .set("Accept", "application/json")
      .responseType("json")
      .set("Authorization", `bearer ${Auth.getToken()}`)
      .end(withPromiseCallback(resolve, reject));
  });
export const get = (uri) =>
  new Promise((resolve, reject) => {
    //uri = dominName+uri

     let request = getDomain();
     request
    //agent
      .get(uri)
      .set("Accept", "application/json")
      .responseType("json")
      .set("Authorization", `bearer ${Auth.getToken()}`)
      .end(withPromiseCallback(resolve, reject));
  });

export const put = (uri, payload) =>
  new Promise((resolve, reject) => {
    let request = getDomain();
    request
      .put(uri)
      .send(payload)
      .set("Accept", "application/json")
      .responseType("json")
      .set("Authorization", `bearer ${Auth.getToken()}`)
      .end(withPromiseCallback(resolve, reject));
  });

export const deleteRequest = (uri, payload) =>
  new Promise((resolve, reject) => {
    let request = getDomain();
    request
      .delete(uri)
      .send(payload)
      .set("Accept", "application/json")
      .responseType("json")
      .set("Authorization", `bearer ${Auth.getToken()}`)
      .end(withPromiseCallback(resolve, reject));
  });

export const withPromiseCallback = (resolve, reject) => (error, response) => {
  if (error) {
    console.error(error);
    if (response && response.status === 401) {
      Auth.deauthenticateUser();
    }
    reject({ error });
  } else {
    resolve(response.body);
  }
};

const getDomain = () => {
  let testRequest = superagentAbsolute(agentAbsolute)(
    process.env.REACT_APP_TESTNET
    //"http://20.40.81.0:8082"
  );

  let productionRequest = superagentAbsolute(agentAbsolute)(
     process.env.REACT_APP_MAINET
    //"http://20.51.212.115:30250"
    );

  let isProduction = localStorage.getItem("modeType");
  
  //Default set test network 
  let request = testRequest;

  //If get boolean value
  request = isProduction ? productionRequest : testRequest;
  
  //If get string value
    if (typeof isProduction === 'string') {
	request = (isProduction === "true" ? productionRequest : testRequest);
  }

  return request;
};
