import React, { Component } from 'react';
import LogoLight from '../../static/images/Logo_light.svg';
import LogoDark from '../../static/images/Logo_dark.svg';

export class HeaderPublic extends Component {
	render() {
		const { mode } = this.props;
		const dark = mode === 'dark';

		return (
			<div className={`header-public w-100 position-fixed`}>
				<a href={window.location.origin}>
					<img
						src={dark ? LogoDark : LogoLight}
						className={`logo`}
						alt="Integra Blockchain Explorer"
					/>
				</a>
			</div>
		);
	}
}

export default HeaderPublic;
