/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import clientJson from '../../../package.json';
// import Version from '../../FabricVersion';
import LogoDark from "../../static/images/Logo_dark.svg";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { themeSelectors } from "../../state/redux/theme";

import DiscordIcon from "../../assets/icons/discord-icon.svg";
import TelegramIcon from "../../assets/icons/telegram-icon.svg";
import TwitterIcon from "../../assets/icons/twitter-icon.svg";
import YoutubeIcon from "../../assets/icons/youtube-icon.svg";

const styles = (theme) => {
  const { type } = theme.palette;
  const dark = type === "dark";
  return {
    root: {
      backgroundColor: dark ? "#242729" : "#111111",
      color: "#D9DBE1",
      fontSize: "14px",
    },
    footer: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "18px 0.5rem",
      margin: "auto",
      maxWidth: "1380px",
    },
  };
};

const FooterView = ({ classes, mode: isDark }) => {
  return (
    <div className={`${classes.root} footer-container`}>
      <div className={`${classes.footer} footer-content`}>
        <div>
          <img src={LogoDark} className={classes.logo} alt="" />
        </div>
        <div className="copyright">
          Integra Blockchain Explorer. All rights reserved
        </div>
        <div className="social-icon-group">
          <a href="https://github.com/IntegraLedger" target="_blank">
            <img src={DiscordIcon} />
          </a>
          <a href="mailto:mrinow@integraledger.com" target="_blank">
            <img src={TelegramIcon} />
          </a>
          <a href="https://twitter.com/integraledger" target="_blank">
            <img src={TwitterIcon} />
          </a>

          <a href="https://university.integraledger.com/" target="_blank">
            <img src={YoutubeIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};
const { modeSelector } = themeSelectors;
export default compose(
  withStyles(styles),
  connect((state) => ({
    mode: modeSelector(state),
  }))
)(FooterView);
